import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
Vue.use(VueRouter);
var routes = [{
  path: '/:id([^/]+)?',
  name: 'Home',
  component: Home
}, {
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    '../views/About.vue');
  }
}];
var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});
export default router;