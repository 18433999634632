import "/builds/thema-developers/nuovo-osix-configurator-usa/node_modules/core-js/modules/es.array.iterator.js";
import "/builds/thema-developers/nuovo-osix-configurator-usa/node_modules/core-js/modules/es.promise.js";
import "/builds/thema-developers/nuovo-osix-configurator-usa/node_modules/core-js/modules/es.object.assign.js";
import "/builds/thema-developers/nuovo-osix-configurator-usa/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.reverse.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Pagination, Dialog, Menu, Input, Radio, RadioGroup, RadioButton, Checkbox, CheckboxButton, CheckboxGroup, Select, Option, OptionGroup, Button, ButtonGroup, Table, TableColumn, DatePicker, Popover, Tooltip, Icon, Row, Col, Upload, Badge, Card, Collapse, CollapseItem, Cascader, Container, Header, Aside, Main, Footer, // Link,
Divider, Image, Calendar, Loading, Form, FormItem, Skeleton, SkeletonItem, Avatar, Slider } from 'element-ui'; // eslint-disable-next-line no-unused-vars

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import "./theme/index.css";
import "./theme/custom.less"; // import CanvasPlugin from 'configurator-base-app'
// @ts-ignore

import CanvasPlugin from 'configurator-base-app';
import Cookies from 'vue-cookies';
import VueI18n from 'vue-i18n'; // import this.$rest from 'configurator-base-app/src/plugins/ajax_request'

import './registerServiceWorker'; // Configurazione configurator-base-app

var settings = {
  VUE_APP_REST_URL: "".concat(process.env.VUE_APP_REST_URL),
  VUE_APP_CONFIG_IDENTIFIER: "OSIXUSA",
  VUE_APP_COLORS_URL: "".concat(process.env.VUE_APP_COLORS_URL),
  VUE_APP_MODEL_URL: "".concat(process.env.VUE_APP_MODEL_URL),
  VUE_APP_CONFIG_DATABASE: "".concat(process.env.VUE_APP_CONFIG_DATABASE),
  VUE_APP_MULTILINETEMPLES: false,
  VUE_APP_HANDLE_SUBLINE_TEMPLES: process.env.VUE_APP_HANDLE_SUBLINE_TEMPLES,
  VUE_APP_IS_USA: process.env.VUE_APP_IS_USA
}; // @ts-ignore

Vue.use(CanvasPlugin, {
  store: store,
  settings: settings
}); // Vue.use(Element, { locale });

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Menu);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer); // Vue.use(Link);

Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Loading.directive);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Avatar);
Vue.use(Slider);
Vue.use(VueI18n);
Vue.use(Cookies); // // eslint-disable-next-line no-unused-vars

Vue.filter('capitalize', function (s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
});
Vue.filter('upperCase', function (s) {
  if (typeof s !== 'string') return '';
  return s.toUpperCase();
});
Vue.filter('finishingLabel', function (f) {
  return f == "S" ? "Lucido" : "Opaco";
});
Vue.filter('asDate', function (d) {
  var date = d.split(" ")[0];
  return date.split("-").reverse().join("/");
});
Vue.filter('decodeLensModel', function (l) {
  if (typeof l !== 'string') {
    return '';
  } else {
    if (l == 'MF1.50SF-UC') {
      return 'MONOFOCALE 1.50 SFERICA-UC';
    } else if (l == 'MF1.56SF-HMC') {
      return 'MONOFOCALE 1.56 SFERICA-HMC';
    } else if (l == 'MF1.56SF-HMC BLUCUT') {
      return 'MONOFOCALE 1.56 SFERICA-HMC BLUCUT';
    } else if (l == 'MF1.60AS-SHMC') {
      return 'MONOFOCALE 1.60 ASFERICA-SHMC';
    } else if (l == 'MF1.67AS-SHMC') {
      return 'MONOFOCALE 1.67 ASFERICA-SHMC';
    }
  }
});
Vue.config.productionTip = false;
locale.use(lang);
var i18n = new VueI18n({
  locale: 'IT',
  //messages,
  fallbackLocale: 'EN'
});
new Vue({
  i18n: i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // @ts-ignore
    Vue.component('Canvas3d', this.$canvas3d); // @ts-ignore

    this.$rest.getCountryCode().then(function (response) {
      i18n.locale = response.data;
    }); // @ts-ignore

    this.$rest.getTranslations().then(function (response) {
      var messages = response.data;

      for (var key in messages) {
        i18n.setLocaleMessage(key, messages[key]);
      }
    });
    this.$store.commit("configurator/setLensesType", 'V');
  }
}).$mount('#app');